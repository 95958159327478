<template>
  <div></div>
</template>
<script>
import request from "@/util/request";

export default {
  name: "Index",
  created() {
    request({
      method: "POST",
      url: "/logout.do"
    },res =>{
      if (res.code ===200){
        this.$message.success("退出成功")
        this.$router.replace("/login")
        this.$store.commit('logout', );
        sessionStorage.clear();
      }
      else{

        this.$message.warning("退出登录失败");
    }
    }, err=>{
      this.$message.warning("退出登录失败");
    }, this.$store.getters.token())
  }
}
</script>
